<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">
        <b-img
          :src="logo"
          :alt="logoAlt"
          fluid
          width="150"
          height="73"
        />
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("auth.redirecting_you") }}</h2>
        <p class="mb-2">
          {{ $t("auth.almost_there") }} ✨.
          {{ $t("auth.redirecting_you_home_page") }}
        </p>
        <b-img :src="imgUrl" alt="Error page" fluid />
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BImg, BLink } from "bootstrap-vue";
import authConfig from "@/configuration/authentication-config";
import { computed, onMounted, ref } from "@vue/composition-api";
import { GENERIC_ROUTES_NAMES } from "@/router/app";
import {
  AUTH_STORE_NAMESPACE,
  REGISTER_AUTH_DATA_ACTION,
  SET_ORG_SETTINGS_ACTION,
} from "@/modules/authnz/models/store";
import { AUTHNZ_ROUTES_NAMES } from "@/router/authnz";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { decodeAndValidate } from "@/modules/authnz/models/token";
import { useOrganizationApi } from "@/modules/authnz/composables/use-organization-api";
import Tenant from "@/plugins/tenant";
import mixpanel from "mixpanel-browser";

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  setup() {
    const { logger, httpClient, route, router, store } =
      useApplicationContext();
    const { getOrganizationSettings } = useOrganizationApi();

    let imgUrl = ref(require("@/assets/images/pages/not-authorized.svg"));
    let logo = ref(
      require("@/assets/images/logo/Logo_MonicaGroups_Couleurs_RVB.svg")
    );
    let logoAlt = ref("Monica Groups");
    let isGroupX = ref(false);
    const tenantId = Tenant.resolveTenantId();

    if (tenantId === "transat" || tenantId === "groupx") {
      isGroupX.value = true;
      logo = require("@/assets/images/logo/GroupX_Logo_Clr.png");
      logoAlt.value = "GroupX";
    }

    computed(() => {
      if (this.$store.state.appConfig.layout.skin === "dark") {
        this.imgUrl = require("@/assets/images/pages/not-authorized-dark.svg");
      }
    });

    onMounted(async () => {
      try {
        let callbackParams = route.value.query;

        logger.debug(
          `Received authentication callback path ${
            route.value.path
          } and query params ${JSON.stringify(callbackParams)}`
        );
        let tokens = await httpClient.get(`${authConfig.authCallback}`, {
          params: callbackParams,
        });

        logger.debug(
          `Committing authenticated user info to application authentication store based on returned tokens`
        );
        store.dispatch(
          `${AUTH_STORE_NAMESPACE}/${REGISTER_AUTH_DATA_ACTION}`,
          tokens.data
        );

        try {
          if (tokens.data && tokens.data.access_token) {
            let decodedAccessToken = decodeAndValidate(
              tokens.data.access_token
            );
            mixpanel.identify(decodedAccessToken.email);

            mixpanel.people.set({
              sub: decodedAccessToken.sub,
              email_verified: decodedAccessToken.email_verified,
              address: decodedAccessToken.address,
              organization_id: decodedAccessToken.organization_id,
              name: decodedAccessToken.name,
              groups: decodedAccessToken.groups,
              phone_number: decodedAccessToken.phone_number,
              preferred_username: decodedAccessToken.preferred_username,
              given_name: decodedAccessToken.given_name,
              family_name: decodedAccessToken.family_name,
              email: decodedAccessToken.email,
            });

            mixpanel.track("User logged in");
          }
        } catch (e) {
          console.debug("no mixpanel");
        }

        try {
          let settings = await getOrganizationSettings();
          store.dispatch(`${AUTH_STORE_NAMESPACE}/${SET_ORG_SETTINGS_ACTION}`, {
            settings,
          });
        } catch (e) {
          console.error("Error: trying loading org config:", e);
        }

        logger.info(`Successfully authenticated user`);
        router.push({name: GENERIC_ROUTES_NAMES.HOME});
      } catch (error) {
        logger.error(
          `Failed to finalize authentication on callback request`,
          error
        );
        // TODO commit clear data from auth store and probably inform backend to invalidate any established session???
        router.push({ name: AUTHNZ_ROUTES_NAMES.AUTHN_ERRORS });
      }
    });

    return {
      imgUrl,
      logo,
      logoAlt,
      isGroupX,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
